import { useSessionStorage } from '@rategravity/marketing-components';
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { PartialParameters } from './types';

const REQUEST_KEY = 'request';

const initialRequestState: PartialParameters = {
  lei: undefined,
  state: undefined,
  loanSize: undefined,
  loanTerm: undefined,
  loanType: undefined,
  email: undefined
};

interface RequestContext {
  request: PartialParameters;
  setRequest: Dispatch<SetStateAction<PartialParameters>>;
}

export const RequestContext = createContext<RequestContext>({
  request: initialRequestState,
  setRequest: () => null
});

export const RequestProvider = ({ children }: PropsWithChildren<{}>) => {
  const [request, setRequest] = useSessionStorage(REQUEST_KEY, initialRequestState);

  return (
    <RequestContext.Provider value={{ request, setRequest }}>{children}</RequestContext.Provider>
  );
};
