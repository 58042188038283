import React from 'react';
import { PageWrapper } from './wrap-page-element';
import wrapWithProvider from './wrap-with-provider';

export const onClientEntry = () => {
  require('regenerator-runtime');
};

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = PageWrapper;
