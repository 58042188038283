import { useSessionStorage } from '@rategravity/marketing-components';
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { PartialParameters } from '.';

const INPUTS_KEY = 'inputs';

export interface Inputs extends PartialParameters {
  mode?: 'manual' | 'queryParams';
}

const initialInputsState: Inputs = {
  lei: undefined,
  state: undefined,
  loanSize: undefined,
  loanTerm: undefined,
  loanType: undefined,
  email: undefined
};
interface InputsContext {
  inputs: Inputs;
  setInputs: Dispatch<SetStateAction<Inputs>>;
}

export const InputsContext = createContext<InputsContext>({
  inputs: initialInputsState,
  setInputs: () => null
});

export const InputsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [inputs, setInputs] = useSessionStorage(INPUTS_KEY, initialInputsState);

  return <InputsContext.Provider value={{ inputs, setInputs }}>{children}</InputsContext.Provider>;
};
