import React, { PropsWithChildren } from 'react';
import {
  ErrorProvider,
  FeedbackProvider,
  InputsProvider,
  LoadingProvider,
  RequestProvider
} from '.';

export const ContextProviders = ({ children }: PropsWithChildren<{}>) => (
  <FeedbackProvider>
    <InputsProvider>
      <RequestProvider>
        <LoadingProvider>
          <ErrorProvider>
            <>{children}</>
          </ErrorProvider>
        </LoadingProvider>
      </RequestProvider>
    </InputsProvider>
  </FeedbackProvider>
);
