import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://048ee9764aac4dd6a1a0d9e8383d7b21@o80434.ingest.sentry.io/6180113',
  environment: process.env.GATSBY_PROD === 'true' ? 'prod' : 'staging',
  integrations: [new BrowserTracing()],
  release: process.env.COMMIT_REF,
  tracesSampleRate: 1.0
});
