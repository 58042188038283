import * as t from 'type-shift';

export type Grades = 'Terrible' | 'Worse than average' | 'Average' | 'Better than average';
export interface Feedback {
  // Average dollar amount of the over/under for each decile of lenders.
  deciles: [number, number, number, number, number, number, number, number, number, number];
  lender: {
    lenderName: string;
    percentile: number; // Number between 0 and 1.
    grade: Grades;
    interestSpread: {
      annual: number;
      lifetime: number;
    };
  };
}

// Throw an error if a value is not explicitly an array of ten numbers.
const decileTupleConverter = t.array(t.number).pipe(
  t.createConverter((value: number[], path) => {
    if (value.length === 10) {
      return value as [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number
      ];
    }

    throw new t.ConverterError(value, 'tuple of ten numbers', path);
  }, 'tuple of ten numbers')
);

export const feedbackConverter = t.strict<Feedback>({
  deciles: decileTupleConverter,
  lender: t.strict({
    lenderName: t.string,
    percentile: t.number,
    grade: t.oneOf(['Terrible', 'Worse than average', 'Average', 'Better than average']),
    interestSpread: t.strict({
      annual: t.number,
      lifetime: t.number
    })
  })
});

// Interface used for the inputs as well as the
// Context that maintains a cache of the request.
// Numerical types for loanSize, loanTerm, and loanType
// correspond to indices for options, defined in `modules/constants.ts`.
// This interface is intentionally a flat structure,
// to make it easier to compare inputs vs. the cached request.
interface Parameters {
  lei: number;
  state: number;
  loanSize: number;
  loanTerm: number;
  loanType: number;
  email: string;
}

// The Partial interface is used because `undefined` is
// the tidiest way to define an input with an empty value.
export type PartialParameters = Partial<Parameters>;

export type ErrorLiterals = 'networkError' | 'notEnoughInformation';

export type Error = null | ErrorLiterals;
