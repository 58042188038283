import { useSessionStorage } from '@rategravity/marketing-components';
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { Error } from '.';

const ERROR_KEY = 'error';

interface ErrorContext {
  error: Error;
  setError: Dispatch<SetStateAction<Error>>;
}

export const ErrorContext = createContext<ErrorContext>({
  error: null,
  setError: () => null
});

export const ErrorProvider = ({ children }: PropsWithChildren<{}>) => {
  const [error, setError] = useSessionStorage<Error>(ERROR_KEY, null);

  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>;
};
