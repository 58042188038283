import { useSessionStorage } from '@rategravity/marketing-components';
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { Feedback } from './types';

const FEEDBACK_KEY = 'feedback';

const initialFeedbackState: Feedback = {
  deciles: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  lender: {
    lenderName: '',
    percentile: 0,
    grade: 'Average',
    interestSpread: {
      annual: 0,
      lifetime: 0
    }
  }
};

interface FeedbackContext {
  feedback: Feedback;
  setFeedback: Dispatch<SetStateAction<Feedback>>;
}

export const FeedbackContext = createContext<FeedbackContext>({
  feedback: initialFeedbackState,
  setFeedback: () => null
});

export const FeedbackProvider = ({ children }: PropsWithChildren<{}>) => {
  const [feedback, setFeedback] = useSessionStorage(FEEDBACK_KEY, initialFeedbackState);

  return (
    <FeedbackContext.Provider value={{ feedback, setFeedback }}>
      {children}
    </FeedbackContext.Provider>
  );
};
