import { useSessionStorage } from '@rategravity/marketing-components';
import React, { createContext, PropsWithChildren } from 'react';

export const LOADING_KEY = 'loading';

interface LoadingContext {
  nowLoading: boolean;
  startLoading: () => void;
  endLoading: () => void;
}

export const LoadingContext = createContext<LoadingContext>({
  nowLoading: false,
  startLoading: () => null,
  endLoading: () => null
});

export const LoadingProvider = ({ children }: PropsWithChildren<{}>) => {
  const [nowLoading, setLoading] = useSessionStorage(LOADING_KEY, false);
  const startLoading = () => setLoading(true);
  const endLoading = () => setLoading(false);

  return (
    <LoadingContext.Provider value={{ nowLoading, startLoading, endLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
