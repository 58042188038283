interface Metadata {
  title: string;
  description: string;
}

// Keys correspond to pathnames.
export const metadata: Record<string, Metadata> = {
  '/tools/lendergrader/': {
    title: 'Lender Grader',
    description:
      'Did your mortgage lender give you a fair deal? See how their rates compare to the national average.'
  },
  '/tools/lendergrader/results/': {
    title: 'Lender Grader Results',
    description:
      'Own Up is a technology company that exists to make sure you get the best deal on your mortgage. Lender Grader enables customers to see how their lender compares against others.'
  }
};
