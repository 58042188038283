import React from 'react';
import {
  breakpoints,
  SplitProvider,
  SplitExperienceProvider
} from '@rategravity/marketing-components';
import { OwnUpThemeWrapper } from '@rategravity/own-up-component-library-legacy';
import {
  OwnUpComponentLibraryProvider,
  ownUpLightTheme
} from '@rategravity/own-up-component-library';
import { createTrackingInstance, TrackingProvider } from '@rategravity/1pt-lib';
import * as Sentry from '@sentry/gatsby';
import { ContextProviders } from './src/context';

const activeSplits = [];

export default ({ element }) => {
  const isLocal = typeof window !== 'undefined' && !window.location.origin.includes('.ownup.com');
  const trackingInstance = createTrackingInstance({
    debug: isLocal,
    url: '/events',
    local: isLocal
  });
  if (trackingInstance.getAnonymousId()) {
    Sentry.setUser({ id: trackingInstance.getAnonymousId() });
  }
  if (typeof window !== 'undefined') {
    return (
      <ContextProviders>
        <OwnUpComponentLibraryProvider theme={ownUpLightTheme}>
          <OwnUpThemeWrapper breakpoints={breakpoints}>
            <TrackingProvider value={trackingInstance}>
              <SplitProvider>
                <SplitExperienceProvider activeSplits={activeSplits}>
                  {element}
                </SplitExperienceProvider>
              </SplitProvider>
            </TrackingProvider>
          </OwnUpThemeWrapper>
        </OwnUpComponentLibraryProvider>
      </ContextProviders>
    );
  }

  return <div />;
};
